<template>
  <div class="shop-billing-line">
    <SkCollapse
      ref="collapse"
      :disabled="isLineCompleted"
    >
      <template slot="labelLeft">
        <div class="shop-billing-line__header__left">
          {{ localShopPaymentInfos.name }}
        </div>
      </template>
      <template slot="labelRight">
        <div class="shop-billing-line__header__right">
          <SkTag
            v-if="!isLineCompleted"
            class="header-tag"
            variant="light"
          >
            {{ $t('fill_billing_info.billing_line.uncompleted_tag') }}
          </SkTag>
          <SkTag
            v-else
            class="header-tag"
            variant="green"
          >
            {{ $t('fill_billing_info.billing_line.completed_tag') }}
          </SkTag>
        </div>
      </template>
      <template slot="content">
        <BillingLineContent
          ref="content"
          v-model="localShopPaymentInfos"
          class="shop-billing-line__content"
          @enable-line-submit="enableLineSubmit"
          @disable-line-submit="disableLineSubmit"
          @disable-submit="emitDisableSubmit"
          @enable-submit="emitEnableSubmit"
        />
        <div class="shop-billing-line__submit">
          <SkButton
            :disabled="isSubmitDisabled"
            :spinner="loading"
            @click="handleSubmit"
          >
            {{ $t('fill_billing_info.billing_line.submit_label') }}
          </SkButton>
        </div>
      </template>
    </skcollapse>
  </div>
</template>

<script>
import {
  SkCollapse,
  SkButton,
  SkTag,
} from '@skelloapp/skello-ui';
import BillingLineContent from '@components/BillingLineContent';
import {
  mapActions,
  mapGetters,
} from 'vuex';

export default {
  name: 'ShopBillingLine',
  components: {
    BillingLineContent,
    SkCollapse,
    SkButton,
    SkTag,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSubmitDisabled: true,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['displayPaymentByCreditCard']),
    localShopPaymentInfos: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', { ...newValue });
      },
    },
    isLineCompleted() {
      // The return prevents update ui side if the state is loading
      if (this.loading) return false;

      return this.displayPaymentByCreditCard ?
        this.localShopPaymentInfos.isCustomerPaymentInfoCompleted :
        this.localShopPaymentInfos.hasValidPaymentMethod;
    },
  },
  methods: {
    ...mapActions(['createChargebeeSource', 'completeCustomerPaymentInfos']),

    disableLineSubmit() {
      this.isSubmitDisabled = true;
      this.$emit('disable-line-submit');
    },
    enableLineSubmit() {
      this.isSubmitDisabled = false;
      this.$emit('enable-line-submit');
    },
    emitDisableSubmit() {
      this.$emit('disable-submit');
    },
    emitEnableSubmit() {
      this.$emit('enable-submit');
    },
    handleSubmit() {
      this.loading = true;

      const submitAction = this.displayPaymentByCreditCard ?
        this.completeCustomerPaymentInfos :
        this.createChargebeeSource;

      submitAction(this.localShopPaymentInfos)
        .then(this.validateLine)
        .catch(() => {
          this.makeAlertToast(this.$t('fill_billing_info.billing_line.billing_error'));
        })
        .finally(() => { this.loading = false; });
    },
    open() {
      this.$refs.collapse.openCollapse();
      if (this.displayPaymentByCreditCard) return;

      this.$nextTick(() => this.$refs.content.$refs.iban.focus());
    },
    validateLine() {
      this.$refs.collapse.closeCollapse();
      this.$emit('validate-shop-payment-infos');
      this.makeSuccessToast(this.$t('fill_billing_info.billing_line.billing_success'));
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-billing-line {
  border: 1px solid $sk-grey-10;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 16px;
}

.shop-billing-line__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.shop-billing-line__header__right {
  display: flex;
}

.header-tag {
  margin: 0 16px;
}

.shop-billing-line__header__left {
  // needed for text-overflow ellipsis to work on customer-name
  // https://css-tricks.com/flexbox-truncated-text/
  min-width: 0;
  font-size: $fs-text-l;
  font-weight: $fw-semi-bold;
  line-height: 17px;
}

.shop-billing-line__content {
  padding: 0 16px 16px 16px;
}

.shop-billing-line__submit {
  padding: 0 16px 16px;
  display: flex;
  justify-content: flex-end;
}
</style>
