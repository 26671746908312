import { FactoryBillingAutomationWeb } from '@skelloapp/svc-billing-automation-sdk';
import { VUE_APP_SVC_BILLING_AUTOMATION_URL } from '@/shared/config';
import { mockQuote } from '@/shared/mockQuote';
import { mockContractChangeQuote } from '@/shared/mockContractChangeQuote';
import { mockRolloutQuote } from '@/shared/mockRolloutQuote';

class BillingAutomationClient {
  constructor() {
    const baseURL = VUE_APP_SVC_BILLING_AUTOMATION_URL;

    this.factory = FactoryBillingAutomationWeb.create(baseURL, {
      clientSource: 'skelloOnboarding:web',
      // Default timeout is 5000, some calls take more than that
      timeout: 10000,
    });
  }

  async getQuoteById(id) {
    // Uncomment one of the next lines to test with mock quotes:
    // return new Promise(resolve => { resolve({ payload: mockQuote }); });
    // return new Promise(resolve => { resolve({ payload: mockContractChangeQuote }); });
    // return new Promise(resolve => { resolve({ payload: mockRolloutQuote }); });
    return this.factory.quote.getById(this.encodeId(id));
  }

  async getCompanyRegistrationsByName(companyName) {
    return this.factory.companyRegistration.getCompanyRegistrationsByName(companyName);
  }

  async getCompanyRegistrationsByShopRegistrationNumber(shopRegistrationNumber) {
    return this.factory.companyRegistration
      .getCompanyRegistrationsByShopRegistrationNumber(shopRegistrationNumber);
  }

  async getCompanyRegistrationsByOrganisationRegistrationNumber(organisationRegistrationNumber) {
    return this.factory.companyRegistration
      .getCompanyRegistrationsByOrganisationRegistrationNumber(organisationRegistrationNumber);
  }

  async createCheckoutByChargebeeIdAndBillingEntity(chargebeeId, entity) {
    return this.factory.checkout.createByChargebeeIdAndBillingEntity(chargebeeId, entity);
  }

  async createChargebeeSource(
    chargebeeId,
    city,
    country,
    email,
    firstName,
    iban,
    lastName,
    line1,
    postalCode) {
    return this.factory.chargebee.createSource(
      chargebeeId,
      city,
      country,
      email,
      firstName,
      iban,
      lastName,
      line1,
      postalCode,
    );
  }

  async validateQuote(id, quote) {
    // Uncomment next line to move to step 2 with mock quote:
    // return new Promise(resolve => { resolve({ payload: mockQuote }); });
    return this.factory.quote.validateQuote(this.encodeId(id), quote);
  }

  async updateQuoteBillingInfos(id, quote) {
    return this.factory.quote.updateQuoteBillingInfos(this.encodeId(id), quote);
  }

  async updateQuoteShopBillingInfos(id, quote) {
    return this.factory.quote.updateQuoteShopBillingInfos(this.encodeId(id), quote);
  }

  async createOrganisation(id, password) {
    return this.factory.organisation.create(this.encodeId(id), password);
  }

  async updateOrganisation({ quoteId, token, sendEmail, parentClusterNodeId }) {
    return this.factory.organisation.update({
      quoteId: this.encodeId(quoteId),
      token,
      sendEmail,
      parentClusterNodeId,
    });
  }

  // SF uses encrypted IDs that contain % characters
  // So we encode to base64 to avoid URL issues
  encodeId(id) {
    return btoa(id);
  }
}

export const billingAutomationClient = new BillingAutomationClient();

export default billingAutomationClient;
