<template>
  <div class="fill-billing-info__wrapper">
    <div
      v-if="!displayPaymentByBankTransfer"
      class="fill-billing-info__payment-radio__container"
    >
      <div class="fill-billing-info__payment-radio__title">
        {{ $t('fill_billing_info.payment_radio.title') }}
      </div>
      <div class="fill-billing-info__payment-radio__row">
        <SkRadio
          v-for="paymentOption in paymentOptions"
          :key="paymentOption.id"
          v-model="selectedPaymentOption"
          class="fill-billing-info__payment-radio__row__option"
          :data-value="paymentOption.id"
          @input="handlePaymentOptionChange"
        >
          {{ paymentOption.text }}
        </SkRadio>
      </div>
    </div>
    <div
      v-if="isBillingPerShops"
      class="fill-billing-info__billing-lines__wrapper"
    >
      <ShopBillingLine
        v-for="(shopPaymentInfos, index) in shopsPaymentInfos"
        ref="shopLines"
        :key="index"
        v-model="shopsPaymentInfos[index]"
        @validate-shop-payment-infos="validateShopPaymentInfos"
        @input="updateShopsPaymentInfos(index, $event)"
      />
    </div>
    <div
      v-else
      class="fill-billing-info__billing-lines__wrapper"
    >
      <OrganisationBillingLine
        v-model="organisationPaymentInfos"
        @disable-submit="emitDisableSubmit"
        @enable-submit="emitEnableSubmit"
        @disable-line-submit="emitInlineDisableSubmit"
        @enable-line-submit="emitInlineEnableSubmit"
      />
    </div>
  </div>
</template>

<script>

import {
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import ShopBillingLine from '@components/ShopBillingLine';
import OrganisationBillingLine from '@components/OrganisationBillingLine';
import { SkRadio } from '@skelloapp/skello-ui';
import { isCustomerPaymentInfoCompleted } from '@/shared/utils/method_helper';

export default {
  name: 'FillBillingInfo',
  components: {
    OrganisationBillingLine,
    ShopBillingLine,
    SkRadio,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paymentOptions: [
        {
          id: 'shop', text: this.$t('fill_billing_info.payment_radio.shop'),
        },
        {
          id: 'organisation', text: this.$t('fill_billing_info.payment_radio.organisation'),
        },
      ],
      selectedPaymentOption: 'shop',
    };
  },
  computed: {
    ...mapGetters(['displayPaymentByCreditCard', 'isBillingOnOrganisation', 'displayPaymentByBankTransfer']),
    localQuote: {
      get() {
        return { ...this.value };
      },
      set(newValue) {
        this.$emit('input', { ...newValue });
      },
    },
    organisationPaymentInfos: {
      get() {
        return {
          billingAddress: this.localQuote.organisation.billing_address,
          customerChargebeeId: this.localQuote.organisation.CB_customer_id,
          paymentOwnerChargebeeId: this.localQuote.organisation.CB_payment_owner_id,
          hasValidPaymentMethod: this.localQuote.organisation.has_valid_payment_method,
          salesforceId: this.localQuote.organisation.id,
          name: this.localQuote.organisation.name,
          registrationNumber: this.localQuote.organisation.registration_number,
          iban: this.localQuote.organisation.iban ?? '',
          isCustomerPaymentInfoCompleted: isCustomerPaymentInfoCompleted(
            this.localQuote.organisation,
          ),
        };
      },
      set(newValue) {
        this.setOrganisationPaymentInfos(newValue);
        const updatedInfos = {
          billing_address: newValue.billingAddress,
          CB_payment_owner_id: newValue.paymentOwnerChargebeeId,
          has_valid_payment_method: newValue.hasValidPaymentMethod,
          name: newValue.name,
          registration_number: newValue.registrationNumber,
          iban: newValue.iban,
        };
        this.updateLocalQuote({
          organisation: updatedInfos,
        });
      },
    },
    shopsPaymentInfos: {
      get() {
        const organisation = this.localQuote.organisation;
        return organisation.shops.map(shop => ({
          billingAddress: shop.billing_address,
          customerChargebeeId: shop.CB_customer_id,
          paymentOwnerChargebeeId: shop.CB_payment_owner_id,
          hasValidPaymentMethod: shop.has_valid_payment_method,
          salesforceId: shop.id,
          name: shop.name,
          registrationNumber: shop.registration_number,
          iban: shop.iban ?? '',
          isCustomerPaymentInfoCompleted: isCustomerPaymentInfoCompleted(shop),
        }));
      },
      set(newValue) {
        this.setShopsPaymentInfos(newValue);
        const updatedShops = newValue.map(shop => ({
          billing_address: shop.billingAddress,
          CB_payment_owner_id: shop.paymentOwnerChargebeeId,
          has_valid_payment_method: shop.hasValidPaymentMethod,
          name: shop.name,
          registration_number: shop.registrationNumber,
          iban: shop.iban,
        }));
        this.updateLocalQuote({
          organisation: {
            shops: updatedShops,
          },
        });
      },
    },
    isBillingPerShops() {
      return this.selectedPaymentOption === 'shop';
    },
    isShopLineCompletedMethod() {
      return this.displayPaymentByCreditCard ?
        shop => shop.isCustomerPaymentInfoCompleted :
        shop => shop.hasValidPaymentMethod;
    },
  },
  mounted() {
    this.populatePaymentInfos();
    this.selectedPaymentOption = this.isBillingOnOrganisation ? 'organisation' : 'shop';
    this.$emit('disable-submit');
    // We need to use setTimeout to allow vue to load the shop refs
    // so that they can be used in openNextLine
    if (this.isBillingPerShops) {
      setTimeout(() => this.openNextLine(), 1);
    }
  },
  methods: {
    ...mapActions(['setPaymentType']),
    ...mapMutations(['populatePaymentInfos', 'setOrganisationPaymentInfos', 'setShopsPaymentInfos']),
    updateShopsPaymentInfos(index, updatedShopInfo) {
      this.shopsPaymentInfos = [
        ...this.shopsPaymentInfos.slice(0, index),
        updatedShopInfo,
        ...this.shopsPaymentInfos.slice(index + 1),
      ];
    },
    validateShopPaymentInfos() {
      if (this.shopsPaymentInfos.every(this.isShopLineCompletedMethod)) {
        this.$emit('enable-submit');
      } else {
        this.openNextLine();
      }
    },
    updateLocalQuote(updates) {
      this.localQuote = {
        ...this.localQuote,
        organisation: {
          ...this.localQuote.organisation,
          ...(updates.organisation || {}), // Updates only if `organisation` is present
          shops: updates.organisation?.shops ?
            this.localQuote.organisation.shops.map((shop, index) => ({
              ...shop,
              ...(updates.organisation.shops[index] || {}), // Merge only if the key `shops[index]` exists
            })) :
            this.localQuote.organisation.shops, // Keep existing shops if `shops` is not provided
        },
      };
    },
    handlePaymentOptionChange(newValue) {
      if (newValue === 'organisation') {
        this.updateLocalQuote({
          organisation: {
            CB_payment_owner_id: this.localQuote.organisation.CB_customer_id,
            shops: this.localQuote.organisation.shops.map(shop => ({
              ...shop,
              CB_payment_owner_id: this.localQuote.organisation.CB_customer_id,
            })),
          },
        });
        this.setPaymentType('Organization');
      } else {
        this.updateLocalQuote({
          organisation: {
            CB_payment_owner_id: null,
            shops: this.localQuote.organisation.shops.map(shop => ({
              ...shop,
              CB_payment_owner_id: shop.CB_customer_id,
            })),
          },
        });
        this.setPaymentType('Shop');
        // We also need to use setTimeout to allow vue to load the shop refs
        // so that they can be used in openNextLine
        setTimeout(() => this.openNextLine(), 1);
      }
      this.$emit('disable-submit');
    },
    openNextLine() {
      const index = this.shopsPaymentInfos.findIndex(
        shop => !this.isShopLineCompletedMethod(shop),
      );
      if (index === -1 || !this.$refs.shopLines) return;

      this.$refs.shopLines[index].open();
    },
    emitDisableSubmit() {
      this.$emit('disable-submit');
    },
    emitEnableSubmit() {
      this.$emit('enable-submit');
    },
    emitInlineDisableSubmit() {
      this.$emit('disable-line-submit');
    },
    emitInlineEnableSubmit() {
      this.$emit('enable-line-submit');
    },
  },
};
</script>

<style lang="scss" scoped>
.fill-billing-info__payment-radio {
  &__container {
    margin: 24px 0px;
    padding: 16px;
    border: 1px solid $sk-grey-10;
    border-radius: 8px;
  }
  &__title {
    font-size: $fs-text-l;
    font-weight: $fw-semi-bold;
    line-height: 17px;
    margin-bottom: 16px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    &__option:last-of-type {
      margin-left: 20px;
    }
  }
}
</style>
