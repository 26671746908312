<template>
  <div class="billing-line">
    <div class="billing-line__label">
      {{ $t('fill_billing_info.billing_line.billing_infos') }}
    </div>
    <div class="input-row__line-container">
      <GlobalEvents
        @keyup.escape="clearSuggestedCompanyRegistrations"
        @click="handleClickOutside"
      />
      <div class="input-row--medium--with-dropdown">
        <SkInput
          ref="nameInput"
          v-model.trim="name"
          type="text"
          class="input-row--large"
          :label="nameInputLabel"
          :errored="!!nameErrorMessage"
          :error-message="nameErrorMessage"
          :debounce="handleNameChanges"
          :debounce-ms="300"
        />
        <div>
          <SkDropdown
            ref="companyNameDropdown"
            placement="bottom-start"
            y-offset="10px"
            :trigger="null"
          >
            <!-- eslint disabled because we need to have 2 menus in the same page -->
            <!-- but html ids are unique -->
            <!-- eslint-disable-next-line vue/v-slot-style -->
            <template v-slot:menu>
              <SuggestedCompanyRegistrations
                v-if="showCompanyNameDropdown"
                :suggested-companies="suggestedCompanyRegistrations"
                :search-input="name"
                :related-input="$refs.nameInput"
                @select-suggested-company="autofillBillingInfos"
              />
            </template>
          </SkDropdown>
        </div>
      </div>
      <div class="input-row--small--with-dropdown">
        <SkInput
          ref="registrationNumberInput"
          v-model.trim="registrationNumber"
          class="input-row--large"
          type="text"
          :label="registrationNumberInputLabel"
          :errored="!!registrationNumberErrorMessage"
          :error-message="registrationNumberErrorMessage"
          :debounce="handleAndValidateRegistrationNumber"
          :debounce-ms="300"
        />
        <SkDropdown
          ref="registrationNumberDropdown"
          placement="bottom-start"
          y-offset="10px"
          :trigger="null"
        >
          <!-- eslint disabled because we need to have 2 menus in the same page -->
          <!-- but html ids are unique -->
          <!-- eslint-disable-next-line vue/v-slot-style -->
          <template v-slot:menu>
            <SuggestedCompanyRegistrations
              v-if="showRegistrationNumberDropdown"
              :suggested-companies="suggestedCompanyRegistrations"
              :search-input="registrationNumber"
              :related-input="$refs.registrationNumberInput"
              @select-suggested-company="autofillBillingInfos"
            />
          </template>
        </SkDropdown>
      </div>
    </div>
    <div class="input-row__line-container">
      <SkInput
        v-if="!displayPaymentByCreditCard && !displayPaymentByBankTransfer"
        ref="iban"
        v-model.trim="iban"
        class="input-row--small"
        type="text"
        :label="$t('fill_billing_info.billing_line.iban_input')"
        :errored="!!ibanErrorMessage"
        :error-message="ibanErrorMessage"
        :debounce="validateIban"
        :debounce-ms="100"
      />
      <SkInput
        v-model.trim="email"
        :class="emailInputClasses"
        type="text"
        :label="$t('fill_billing_info.billing_line.email_input')"
        :errored="!!emailErrorMessage"
        :error-message="emailErrorMessage"
        :debounce="validateEmail"
        :debounce-ms="100"
      />
    </div>
    <div class="billing-line__label">
      {{ $t('fill_billing_info.billing_line.billing_address_label') }}
    </div>
    <div class="input-row__line-container">
      <SkInput
        v-model.trim="address"
        class="input-row--large"
        type="text"
        :label="$t('fill_billing_info.billing_line.address_input')"
        :errored="!!addressErrorMessage"
        :error-message="addressErrorMessage"
        :debounce="validatePresence('address')"
        :debounce-ms="100"
      />
    </div>
    <div class="input-row__line-container">
      <SkInput
        v-model.trim="city"
        class="input-row--small"
        type="text"
        :label="$t('fill_billing_info.billing_line.city_input')"
        :errored="!!cityErrorMessage"
        :error-message="cityErrorMessage"
        :debounce="validatePresence('city')"
        :debounce-ms="100"
      />
      <SkInput
        v-model.trim="zipcode"
        class="input-row--small"
        type="text"
        :label="$t('fill_billing_info.billing_line.zipcode_input')"
        :errored="!!zipcodeErrorMessage"
        :error-message="zipcodeErrorMessage"
        :debounce="validatePresence('zipcode')"
        :debounce-ms="100"
      />
      <!-- We want to display country in a SkSelect but it can't be changed -->
      <!-- So the only option is the country we receive from Salesforce -->
      <!-- This is why countryOption is a hardcoded single value array -->
      <SkSelectV2
        v-model="paymentInfos.billingAddress.country"
        class="input-row--small"
        type="text"
        :label="$t('fill_billing_info.billing_line.country_input')"
        :options="countryOption"
        disabled
      />
    </div>
    <div v-if="displayPaymentByBankTransfer">
      <p class="sk-text-large-semibold">
        {{ $t('fill_billing_info.billing_line.bank_transfer.payment_method') }}
      </p>
      <p class="sk-text-medium-regular bank-transfert-description">
        {{ $t('fill_billing_info.billing_line.bank_transfer.payment_method_label') }}
        <span class="span-bank-transfer">
          {{ $t('fill_billing_info.billing_line.bank_transfer.payment_method_span') }}
        </span>
        <br>
        {{ $t('fill_billing_info.billing_line.bank_transfer.payment_method_description') }}
      </p>
    </div>
    <CreditCardSection
      v-if="displayPaymentByCreditCard"
      v-model="paymentInfos"
      @credit-card-added="handleNewCreditCard"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  SkInput,
  SkSelectV2,
  SkDropdown,
} from '@skelloapp/skello-ui';
import GlobalEvents from 'vue-global-events';
import { billingAutomationClient } from '@/shared/utils/clients/billingAutomationClient';
import {
  isValidEmail,
  isValidIban,
  isValidSiret,
  isValidSiren,
} from '@/shared/utils/validators';
import { removeWhiteSpaces } from '@/shared/utils/method_helper';
import SuggestedCompanyRegistrations from './SuggestedCompanyRegistrations/index.vue';
import CreditCardSection from './CreditCardSection/index.vue';
import { getNaf } from '@/shared/utils/naf';

export default {
  name: 'BillingLineContent',
  components: {
    GlobalEvents,
    SkInput,
    SkSelectV2,
    SkDropdown,
    CreditCardSection,
    SuggestedCompanyRegistrations,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      countryOption: null,
      registrationNumberWithSpacesAllowed: null,
      ibanWithSpacesAllowed: null,
      nameErrorMessage: null,
      registrationNumberErrorMessage: null,
      ibanErrorMessage: null,
      emailErrorMessage: null,
      addressErrorMessage: null,
      cityErrorMessage: null,
      zipcodeErrorMessage: null,
      suggestedCompanyRegistrations: [],
      isDropDownOpen: {
        companyName: false,
        registrationNumber: false,
      },
    };
  },
  computed: {
    ...mapGetters(['displayPaymentByCreditCard', 'isBillingOnOrganisation', 'displayPaymentByBankTransfer']),
    paymentInfos: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', { ...newValue });
      },
    },
    emailInputClasses() {
      return {
        'input-row--large': this.displayPaymentByCreditCard || this.displayPaymentByBankTransfer,
        'input-row--medium': !this.displayPaymentByCreditCard && !this.displayPaymentByBankTransfer,
      };
    },
    areGenericInputsInvalid() {
      return !!this.nameErrorMessage || !!this.registrationNumberErrorMessage ||
        !!this.emailErrorMessage || !!this.addressErrorMessage || !!this.cityErrorMessage ||
        !!this.zipcodeErrorMessage || !this.name || !this.registrationNumber ||
        !this.email || !this.address || !this.city || !this.zipcode;
    },
    areInputsInvalid() {
      if (this.displayPaymentByCreditCard) {
        return this.areGenericInputsInvalid || !this.paymentInfos.hasValidPaymentMethod;
      }
      if (this.displayPaymentByBankTransfer) {
        return this.areGenericInputsInvalid;
      }
      return this.areGenericInputsInvalid || !!this.ibanErrorMessage || !this.iban;
    },
    address: {
      get() {
        return this.paymentInfos.billingAddress.line1;
      },
      set(value) {
        this.updatePaymentInfos('billingAddress', {
          ...this.paymentInfos.billingAddress,
          line1: value,
        });
      },
    },
    city: {
      get() {
        return this.paymentInfos.billingAddress.city;
      },
      set(value) {
        this.updatePaymentInfos('billingAddress', {
          ...this.paymentInfos.billingAddress,
          city: value,
        });
      },
    },
    email: {
      get() {
        return this.paymentInfos.billingAddress.email;
      },
      set(value) {
        this.updatePaymentInfos('billingAddress', {
          ...this.paymentInfos.billingAddress,
          email: value,
        });
      },
    },
    iban: {
      get() {
        return this.ibanWithSpacesAllowed &&
          removeWhiteSpaces(this.ibanWithSpacesAllowed);
      },
      set(value) {
        this.ibanWithSpacesAllowed = value;
        this.updatePaymentInfos('iban', this.iban);
      },
    },
    name: {
      get() {
        return this.paymentInfos.name;
      },
      set(value) {
        this.validatePresence('name');
        this.updatePaymentInfos('name', value);
      },
    },
    registrationNumber: {
      get() {
        return this.registrationNumberWithSpacesAllowed &&
          removeWhiteSpaces(this.registrationNumberWithSpacesAllowed);
      },
      set(value) {
        this.validateRegistrationNumber();
        this.registrationNumberWithSpacesAllowed = value;
        this.updatePaymentInfos('registrationNumber', this.registrationNumber);
      },
    },
    zipcode: {
      get() {
        return this.paymentInfos.billingAddress.zip;
      },
      set(value) {
        this.updatePaymentInfos('billingAddress', {
          ...this.paymentInfos.billingAddress,
          zip: value,
        });
      },
    },
    nameInputLabel() {
      const i18nKey = this.isBillingOnOrganisation ?
        'organisation_name_input' :
        'shop_name_input';

      return this.$t(`fill_billing_info.billing_line.${i18nKey}`);
    },

    // Only INSEE API is implemented to look for company registrations for the moment.
    isSupportedCountryForRegistrationLookup() {
      return this.paymentInfos.billingAddress.country_code === 'FR';
    },
    registrationNumberInputLabel() {
      const i18nKey = this.isBillingOnOrganisation ?
        'organisation_registration_number_input' :
        'shop_registration_number_input';

      return this.$t(`fill_billing_info.billing_line.${i18nKey}`);
    },
    showCompanyNameDropdown() {
      return this.suggestedCompanyRegistrations.length > 0 &&
        this.isDropDownOpen.companyName;
    },
    showRegistrationNumberDropdown() {
      return this.suggestedCompanyRegistrations.length > 0 &&
        this.isDropDownOpen.registrationNumber;
    },
  },
  created() {
    this.countryOption = [{
      id: this.paymentInfos.billingAddress?.country,
      text: this.$t(`common.countries.${this.paymentInfos.billingAddress?.country_code}`),
    }];
    this.registrationNumber = this.paymentInfos?.registrationNumber;
    this.iban = this.paymentInfos?.iban;

    if (this.iban) this.validateIban();
    if (this.email) this.validateEmail();
    if (this.registrationNumber) this.validateRegistrationNumber();
  },
  updated() {
    if (this.areInputsInvalid) {
      this.$emit(
        this.isBillingOnOrganisation ? 'disable-submit' : 'disable-line-submit',
      );
      return;
    }

    this.$emit(
      this.isBillingOnOrganisation ? 'enable-submit' : 'enable-line-submit',
    );
  },
  methods: {
    lookupCompanyRegistrationsByName() {
      return billingAutomationClient
        .getCompanyRegistrationsByName(this.name);
    },
    lookupCompanyRegistrationsByRegistrationNumber() {
      if (this.isBillingOnOrganisation) {
        return billingAutomationClient
          .getCompanyRegistrationsByOrganisationRegistrationNumber(this.registrationNumber);
      }
      return billingAutomationClient
        .getCompanyRegistrationsByShopRegistrationNumber(this.registrationNumber);
    },
    populateSuggestedCompanyRegistrations(suggestedCompanies, searchedText) {
      this.suggestedCompanyRegistrations = suggestedCompanies.map(suggestedCompany => ({
        address: suggestedCompany.address,
        city: suggestedCompany.city,
        creationDate: suggestedCompany.creationDate,
        shopRegistrationNumber: suggestedCompany.shopRegistrationNumber,
        displayLabel: this.highlightMatch(
          suggestedCompany.name,
          searchedText,
        ),
        // We need to remove the dot in the main activity to be able to use the naf filter
        // Dots are added by the INSEE API
        mainActivity: getNaf(suggestedCompany.mainActivity.replaceAll('.', '')),
        name: suggestedCompany.name,
        organisationRegistrationNumber: suggestedCompany.organisationRegistrationNumber,
        zipCode: suggestedCompany.zipCode,
      }));
    },
    handleNameChanges() {
      this.validatePresence('name');
      if (!this.isSupportedCountryForRegistrationLookup) return;
      if (this.name.length < 3) {
        this.clearSuggestedCompanyRegistrations();
        return;
      }

      this.lookupCompanyRegistrationsByName()
        .then(response => {
          this.populateSuggestedCompanyRegistrations(response, this.name);
          this.isDropDownOpen.companyName = true;
          this.isDropDownOpen.registrationNumber = false;
          this.$refs.companyNameDropdown.open();
          this.$refs.registrationNumberDropdown.hide();
        }).catch(this.clearSuggestedCompanyRegistrations);
    },
    validatePresence(attribute) {
      const value = this[attribute];

      if (value === '') {
        this[`${attribute}ErrorMessage`] = this.$t('fill_billing_info.billing_line.error_input_mandatory');
      } else {
        this[`${attribute}ErrorMessage`] = null;
      }
    },
    validateIban() {
      this.validatePresence('iban');
      if (this.iban && !isValidIban(this.iban)) {
        this.ibanErrorMessage = this.$t('fill_billing_info.billing_line.iban_invalid');
      }
    },
    validateEmail() {
      this.validatePresence('email');
      if (this.email && !isValidEmail(this.email)) {
        this.emailErrorMessage = this.$t('fill_billing_info.billing_line.email_invalid');
      }
    },
    handleAndValidateRegistrationNumber() {
      this.handleRegistrationNumberInput();
      this.validateRegistrationNumber();
    },
    handleRegistrationNumberInput() {
      if (!this.isSupportedCountryForRegistrationLookup) return;
      if (this.registrationNumber.length < 3) {
        this.clearSuggestedCompanyRegistrations();
        return;
      }

      this.lookupCompanyRegistrationsByRegistrationNumber()
        .then(response => {
          this.populateSuggestedCompanyRegistrations(response, '');
          this.isDropDownOpen.registrationNumber = true;
          this.isDropDownOpen.companyName = false;
          this.$refs.registrationNumberDropdown.open();
          this.$refs.companyNameDropdown.hide();
        }).catch(this.clearSuggestedCompanyRegistrations);
    },
    validateRegistrationNumber() {
      this.validatePresence('registrationNumber');
      if (!this.registrationNumber || !this.isSupportedCountryForRegistrationLookup) return;

      if (this.isBillingOnOrganisation && !isValidSiren(this.registrationNumber)) {
        this.registrationNumberErrorMessage = this.$t('fill_billing_info.billing_line.siren_invalid');
      } else if (!this.isBillingOnOrganisation && !isValidSiret(this.registrationNumber)) {
        this.registrationNumberErrorMessage = this.$t('fill_billing_info.billing_line.siret_invalid');
      }
    },

    // for the companyName "L'AMI DU PAIN" with 'AMI D' as input, we return the array:
    // [{ text: "L'" },
    //  { text: 'AMI D', isHighlighted: true },
    //  { text: 'U PAIN' }]
    //
    highlightMatch(result, searchedText) {
      const matching = result.toLowerCase().match(searchedText.toLowerCase());
      if (!matching) return [{ text: result }];

      const formattedText = [
        { text: searchedText, isHighlighted: true },
        { text: result.substring(matching.index + searchedText.length) },
      ];

      if (matching.index > 0) {
        formattedText.unshift({ text: result.substring(0, matching.index) });
      }

      return formattedText;
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown?.$el?.contains(event.target)) return;

      this.clearSuggestedCompanyRegistrations();
    },
    clearSuggestedCompanyRegistrations() {
      this.suggestedCompanyRegistrations = [];
      this.isDropDownOpen.registrationNumber = false;
      this.isDropDownOpen.companyName = false;
      this.$refs.companyNameDropdown.hide();
      this.$refs.registrationNumberDropdown.hide();
    },
    autofillBillingInfos(company) {
      this.name = company.name;
      this.registrationNumber = this.isBillingOnOrganisation ? // SIRET for shop, SIREN for organisation
        company.organisationRegistrationNumber : company.shopRegistrationNumber;
      this.address = company.address;
      this.city = company.city;
      this.zipcode = company.zipCode;
      this.clearSuggestedCompanyRegistrations();
    },
    handleNewCreditCard() {
      if (this.areInputsInvalid) return;

      this.$emit(
        this.isBillingOnOrganisation ? 'enable-submit' : 'enable-line-submit',
      );
    },
    updatePaymentInfos(attribute, value) {
      this.paymentInfos = {
        ...this.paymentInfos,
        [attribute]: value,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.billing-line__label {
  margin: 24px 0 16px;
  font-size: $fs-text-l;
  font-weight: $fw-semi-bold;
  line-height: 17px;

  &:first-of-type {
    margin: 14px 0 16px;
  }
}
.span-bank-transfer {
  font-weight: 600;
}
.bank-transfert-description {
  margin-top: 16px;
  margin-bottom: 0px;
}
.input-row__line-container {
  display: flex;
  justify-content: space-between;
  margin: 8px 0 0;
}

.input-row {
  &__input-with-dropdown {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  &--large {
    max-width: 100%;
  }

  &--medium {
    max-width: 66.4%;

    &--with-dropdown {
      min-width: 66.4%;
    }
  }

  &--small {
    max-width: 32.8%;

    &--with-dropdown {
      min-width: 32.8%;
    }
  }
}
</style>
