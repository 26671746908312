<template>
  <div class="credit-card-section">
    <div class="credit-card-section__header">
      <div class="credit-card-section__header__label">
        {{ $t('fill_billing_info.billing_line.credit_card.label') }}
      </div>
      <SkTag
        v-if="hasValidPaymentMethod"
        class="credit-card-section__header__tag"
        variant="green"
      >
        {{ $t('fill_billing_info.billing_line.credit_card.tag') }}
      </SkTag>
    </div>
    <SkButton
      :fill="buttonFill"
      @click="openCheckout"
    >
      {{ buttonText }}
    </SkButton>
  </div>
</template>

<script>
import {
  SkButton,
  SkTag,
} from '@skelloapp/skello-ui';
import {
  mapActions,
  mapState,
} from 'vuex';
import { billingAutomationClient } from '@/shared/utils/clients/billingAutomationClient';
import { VUE_APP_CHARGEBEE_SITE } from '@/shared/config';

export default {
  name: 'CreditCardSection',
  components: { SkButton, SkTag },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chargebeeInstance: window.Chargebee.init({ site: VUE_APP_CHARGEBEE_SITE }),
    };
  },
  computed: {
    ...mapState(['quote']),
    paymentInfos: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', { ...newValue });
      },
    },

    buttonFill() {
      return this.hasValidPaymentMethod ? 'outlined' : 'filled';
    },
    buttonText() {
      return this.hasValidPaymentMethod ?
        this.$t('fill_billing_info.billing_line.credit_card.update_label') :
        this.$t('fill_billing_info.billing_line.credit_card.create_label');
    },
    hasValidPaymentMethod() {
      return this.paymentInfos.hasValidPaymentMethod;
    },
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessageReceived);
  },
  methods: {
    showCheckoutOpenErrorToast() {
      this.makeAlertToast(this.$t('fill_billing_info.billing_line.checkout_open_error'));
    },
    openCheckout() {
      window.addEventListener('message', this.handleMessageReceived);
      // 'this' unavailable inside openCheckout function
      const thisComponent = this;
      try {
        this.chargebeeInstance.openCheckout({
          hostedPage() {
            return billingAutomationClient
              .createCheckoutByChargebeeIdAndBillingEntity(
                thisComponent.paymentInfos.paymentOwnerChargebeeId,
                thisComponent.quote.billing_entity,
              );
          },
          close() {
            // There are multiple billing lines,
            // We need to make sure that only the right billing line is listening to messages
            // So we start listening on click and stop listening when modal closes
            window.removeEventListener('message', thisComponent.handleMessageReceived);
          },
          error() {
            thisComponent.showCheckoutOpenErrorToast();
          },
        });
      } catch (error) {
        this.chargebeeInstance.closeAll();
        this.showCheckoutOpenErrorToast();
      }
    },
    handleMessageReceived(event) {
      // event.data is either Object, or a simple name string
      if (event?.data?.key !== 'cb.payment_source.add') return;

      if (event?.data?.status === 'success') {
        this.paymentInfos = { ...this.paymentInfos, hasValidPaymentMethod: true };
        this.$emit('credit-card-added');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-card-section {
  margin-top: 24px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: flex-start;

  &__header {
    display: flex;

    &__label {
      flex: 1;
      font-size: $fs-text-l;
      font-weight: $fw-semi-bold;
      line-height: 17px;
    }

    &__tag {
      position: relative;
      left: 12px;
      bottom: 4px;
    }
  }
}
</style>
